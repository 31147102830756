import React, { useEffect, useState, useRef } from 'react';
import mainLogo from './assets/main_logo.png';
import smokeSensorIcon from './assets/smoke_sensor.png';
import smokeSensorTrouble from './assets/smoke_sensor_trouble.png';
import smokeSensorOutdated from './assets/smoke_sensor_outdated.png';

import './CircleSensorApp.css'; // Import the CSS file

const CircleSensorApp = () => {
  const [sensorData, setSensorData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [dialogMessage, setDialogMessage] = useState('');
    const [siteName, setSiteName] = useState(''); // New state for site name

  const socketRef = useRef(null); // Ref to store the WebSocket instance

  useEffect(() => {
    const handleSocketMessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.sensor_name === 'site_name') {
        setSiteName(message.name); // Update Site Name
      } else {
          setSensorData((prevData) => {
            const updatedData = [...prevData];
            const index = updatedData.findIndex((data) => data.sensor_name === message.sensor_name);
            if (index !== -1) {
              updatedData[index] = message;
            } else {
              updatedData.push(message);
            }
            return updatedData;
      });
      }
    };

    const connectWebSocket = () => {
      // eslint-disable-next-line no-restricted-globals
      const page = location.pathname.replace('/', '');
      socketRef.current = new WebSocket('ws://' + process.env.REACT_APP_WS_SERVER_IP + ':5000/' + page);
      socketRef.current.onmessage = handleSocketMessage;
      socketRef.current.onclose = () => {
        console.log('WebSocket connection closed. Reconnecting...');
        setTimeout(connectWebSocket, 2000); // Retry connection after 2 seconds
      };
    };

    connectWebSocket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);

  const handleClickRow = (sensorNumber, sensorName) => {
    setSelectedSensor(sensorNumber);
    setDialogOpen(true);
    setDialogMessage(`לאפס התראות עבור ${sensorName}?`);
  };

  const handleDialogResponse = (response) => {
    if (response === 'yes' && selectedSensor) {
      const command = `${selectedSensor}_reset`;
      const postData = { command };

      fetch('http://' + process.env.REACT_APP_WS_SERVER_IP + ':7000/get_command', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      })
        .then((response) => {
          // Handle the response
          console.log('HTTP POST response:', response);
        })
        .catch((error) => {
          // Handle errors
          console.error('Error sending HTTP POST:', error);
        });
    }

    setDialogOpen(false);
    setSelectedSensor(null);
  };

  const handleDialogOutsideClick = () => {
    if (dialogOpen) {
      handleDialogResponse('no');
    }
  };

  return (
    <div style={{ direction: 'rtl', backgroundColor: '#222', color: '#fff', display: 'flex', flexDirection: 'column', minHeight: '115vh', flex: 1, overflow: 'auto' }}>
        <header style={{ padding: '20px', textAlign: 'center', fontSize: '24px', fontWeight: 'bold', color: 'purple' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={mainLogo} alt="Push The Button" style={{ width: '200px', height: 'auto' }}/>
          </div>
          <div style={{ textAlign: 'center', marginTop: '2px' }}>
            <h3>{siteName}</h3> {/* Display Site Name */}
          </div>
        </header>
       {sensorData.map((data, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            backgroundColor: index % 2 === 0 ? '#333' : '#444',
            borderBottom: '1px solid #555',
            animation: 'fade-in 2.5s ease',
          }}
          onClick={() => handleClickRow(data.sensor_number, data.sensor_name)}
        >
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'right' }}>{data.sensor_name}</span>
            <div style={{ fontSize: '18px', marginTop: '5px', whiteSpace: 'pre-line', textAlign: 'right' }}>
              {data.sensor_temperature} : {data.final_status}
              <br />

            </div>
          </div>
        <div>
            {data.status === 'OK' && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                  src={smokeSensorIcon}
                  alt="Smoke Sensor"
                  style={{
                    width: '34px',
                    height: '34px',
                    animation: 'blink 2s infinite',
                    margin: 'auto'
                  }}
                />
                <div style={{ fontSize: '18px', marginTop: '5px', whiteSpace: 'pre-line', textAlign: 'center' }}>
                  {data.sensor_latest_heartbeat}
                </div>
              </div>
            )}
          {data.status === 'BAD' && (
             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                  src={smokeSensorTrouble}
                  alt="Smoke Sensor"
                  style={{
                    width: '34px',
                    height: '34px',
                    animation: 'blink 2s infinite',
                    margin: 'auto'
                  }}
                />
                <div style={{ fontSize: '18px', marginTop: '5px', whiteSpace: 'pre-line', textAlign: 'center' }}>
                  {data.sensor_latest_heartbeat}
                </div>
              </div>
          )}
          {data.status === 'NOTUPDATED' && (
             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                  src={smokeSensorOutdated}
                  alt="Smoke Sensor"
                  style={{
                    width: '34px',
                    height: '34px',
                    animation: 'blink 2s infinite',
                    margin: 'auto'
                  }}
                />
                <div style={{ fontSize: '18px', marginTop: '5px', whiteSpace: 'pre-line', textAlign: 'center' }}>
                  {data.sensor_latest_heartbeat}
                </div>
              </div>
          )}
          {/* Add more conditions for other sensor types */}
        </div>
      </div>
    ))}

        {dialogOpen && (
        <div className="dialog-overlay" onClick={handleDialogOutsideClick}>
          <div className="dialog-box">
            <p dangerouslySetInnerHTML={{ __html: dialogMessage }}></p>
            <div>
              <button onClick={() => handleDialogResponse('yes')}>כן</button>
              <button onClick={() => handleDialogResponse('no')}>לא</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CircleSensorApp;
